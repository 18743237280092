<template>
  <vx-card title="Survey Allocation">
    <div class="vx-input-group flex">
      <vs-button v-if="hasPermission('create')" class="mt-2 mr-2" color="success" type="border" icon-pack="feather" icon="icon-plus"
        @click="handleCreate()">Create</vs-button>
    </div>
    <div class="vx-row">
      <div class="vx-col sm:w-1/1 w-full mb-base">
        <data-table :baseUrl="this.baseUrl"></data-table>
      </div>
    </div>
  </vx-card>
</template>
<script>
import DataTable from "./DataTable.vue";
export default {
  components: {
    DataTable,
  },
  data() {
    return {
      baseUrl: "/api/sfa/v1/survey-allocation",
    };
  },
  methods: {
    handleCreate() {
      this.$router.push({
        name: "survey-allocation-create",
      });
    },
  },
  mounted() {
    this.$store.dispatch("user/getPermissions", "survey-allocation");
  },
  watch: {},
};
</script>
